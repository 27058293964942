import { SvgIcon, SvgIconProps } from '@mui/material';

const WarningIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props} width="24" height="24" viewBox="0 0 24 24" data-testid="CustomWarningIcon">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 15C12.5523 15 13 14.5523 13 14V9.00001C13 8.44772 12.5523 8.00001 12 8.00001C11.4477 8.00001 11 8.44772 11 9.00001V14C11 14.5523 11.4477 15 12 15Z"
        fill={props.color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.6746 17.5044L12.8696 5.56347C12.486 4.8903 11.5156 4.89031 11.132 5.56347L4.32701 17.5044C3.94709 18.1711 4.42852 18.9996 5.19583 18.9996H18.8058C19.5731 18.9996 20.0545 18.1711 19.6746 17.5044ZM14.6072 4.57321C13.4564 2.55372 10.5452 2.55372 9.39433 4.57321L2.58937 16.5142C1.44963 18.5141 2.89391 20.9996 5.19583 20.9996H18.8058C21.1077 20.9996 22.552 18.5141 21.4122 16.5142L14.6072 4.57321Z"
        fill={props.color}
      />
      <path
        d="M13 17C13 17.5523 12.5523 18 12 18C11.4477 18 11 17.5523 11 17C11 16.4477 11.4477 16 12 16C12.5523 16 13 16.4477 13 17Z"
        fill={props.color}
      />
    </SvgIcon>
  );
};

export default WarningIcon;
