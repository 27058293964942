import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import { ChipProps, Chip as MuiChip, styled } from '@mui/material';
import { forwardRef } from 'react';

interface Props
  extends Pick<
    ChipProps,
    'avatar' | 'label' | 'disabled' | 'size' | 'onClick' | 'sx' | 'onDelete' | 'deleteIcon'
  > {
  backgroundColor?: string;
  textColor?: string;
  rounded?: boolean;
}

const StyledChip = styled(MuiChip, {
  shouldForwardProp: (prop) =>
    prop !== 'backgroundColor' && prop !== 'textColor' && prop !== 'rounded',
})<Pick<Props, 'backgroundColor' | 'textColor' | 'rounded'>>(
  ({
    theme,
    backgroundColor = theme.palette.grey.A100,
    textColor = theme.palette.text.primary,
    rounded,
  }) => ({
    fontWeight: 500,
    background: backgroundColor,
    color: textColor,
    '&.MuiChip-sizeSmall': {
      borderRadius: rounded ? theme.shape.borderRadius * 100 : theme.shape.borderRadius,
      '& .MuiChip-label': {
        ...theme.typography.body3,
        padding: theme.spacing(1, 1.5),
      },
    },
    '&.MuiChip-sizeMedium': {
      borderRadius: rounded ? theme.shape.borderRadius * 100 : theme.shape.borderRadius * 2,
      '& .MuiChip-label': {
        ...theme.typography.body2,
        padding: theme.spacing(1.25, 2),
      },
    },
    '& .MuiChip-deleteIcon': {
      color: textColor,
      marginRight: theme.spacing(1.5),
      fontSize: '18px',
    },
  })
);

const Chip = forwardRef<HTMLDivElement, Props>(
  ({ backgroundColor, textColor, rounded = false, ...rest }, ref) => {
    return (
      <StyledChip
        ref={ref}
        backgroundColor={backgroundColor}
        textColor={textColor}
        variant="filled"
        rounded={rounded}
        deleteIcon={<CloseRoundedIcon />}
        {...rest}
      />
    );
  }
);

export default Chip;
