import { Card, Typography, Box, useTheme } from '@mui/material';
import type { PortableTextBlock } from '@portabletext/types';
import { RichTextRenderer } from '../../components/RichTextRenderer/RichTextRenderer';

interface Props {
  title?: string;
  body: PortableTextBlock[];
}

export function RichText({ title, body }: Props): JSX.Element {
  const { breakpoints } = useTheme();
  return (
    <Card>
      <Box maxWidth="md" mx="auto" sx={{ py: 0, [breakpoints.up('md')]: { py: 2 } }}>
        <Typography variant="h3" sx={{ textWrap: 'balance', hyphens: 'auto' }} component="h2">
          {title}
        </Typography>
        <RichTextRenderer blocks={body} />
      </Box>
    </Card>
  );
}
