import { BrandadminTFunction, BrandTFunction, useBrandadminTFunction } from '@dap-admin/i18n';
import {
  BrandProperty,
  BrandPropertyInheritType,
  DataownerBasic,
  InformationModelType,
  InheritableProperty,
  LocationOverview,
  PropertyDetail,
  PropertyValueTypes,
  TransformedInheritablePropertyValue,
} from '@dap-admin/types';
import { AppTFunction } from '@dap-common/i18n';
import { createFilterOptions, FilterOptionsState } from '@mui/material';
import { formatDate } from 'date-fns';

export interface KeyGroupOptionType {
  inputValue?: string;
  keyGroup: string;
}

export const keyGroupFilterOptions = createFilterOptions<KeyGroupOptionType>();

export const getKeyGroupOptions = (
  properties: Array<PropertyDetail>
): readonly KeyGroupOptionType[] => {
  return properties
    .map(({ keyGroup }) => keyGroup || '')
    .filter((keyGroup) => !!keyGroup || keyGroup !== null)
    .filter((keyGroup, index, array) => array.indexOf(keyGroup) === index)
    .map((keyGroup) => ({ keyGroup }));
};

export const getValueTypeLabel = (valueType: string, t: BrandadminTFunction) => {
  switch (valueType) {
    case PropertyValueTypes.string:
      return t('brandadmin.properties.types.string');
    case PropertyValueTypes.boolean:
      return t('brandadmin.properties.types.boolean');
    case PropertyValueTypes.number:
      return t('brandadmin.properties.types.number');
    case PropertyValueTypes.date:
      return t('brandadmin.properties.types.date');
    case PropertyValueTypes.enum:
      return t('brandadmin.properties.types.enum');
    case PropertyValueTypes.multipleChoice:
      return t('brandadmin.properties.types.multipleChoice');
    default:
      return '';
  }
};

export const getInheritTypeLabel = (
  inheritType: BrandPropertyInheritType,
  t: BrandadminTFunction,
  tBrand: BrandTFunction
) => {
  switch (inheritType) {
    case BrandPropertyInheritType.BRAND_ONLY:
      return t('brandadmin.properties.create.inheritable.brandOnly');
    case BrandPropertyInheritType.DATAOWNERS:
      return t('brandadmin.properties.create.inheritable.allDataowners', {
        dataowners: tBrand('brand.dataowner.name.plural'),
      });
    case BrandPropertyInheritType.LOCATIONS:
      return t('brandadmin.properties.create.inheritable.allLocations', {
        locations: tBrand('brand.location.name.plural'),
      });
    case BrandPropertyInheritType.DATAOWNERS_AND_LOCATIONS:
      return t('brandadmin.properties.create.inheritable.allDataownersAndLocations', {
        dataowners: tBrand('brand.dataowner.name.plural'),
        locations: tBrand('brand.location.name.plural'),
      });
    default:
      return '';
  }
};

export const getInheritType = (
  property: BrandProperty,
  t: BrandadminTFunction,
  tBrand: BrandTFunction
) => {
  switch (true) {
    case !property.dataownerInheritable && !property.locationInheritable:
      return getInheritTypeLabel(BrandPropertyInheritType.BRAND_ONLY, t, tBrand);

    case property.dataownerInheritable && property.locationInheritable:
      return getInheritTypeLabel(BrandPropertyInheritType.DATAOWNERS_AND_LOCATIONS, t, tBrand);

    case property.dataownerInheritable:
      return getInheritTypeLabel(BrandPropertyInheritType.DATAOWNERS, t, tBrand);

    case property.locationInheritable:
      return getInheritTypeLabel(BrandPropertyInheritType.LOCATIONS, t, tBrand);
    default:
      return '';
  }
};

export const getInheritableType = (property: BrandProperty | InheritableProperty) => {
  switch (true) {
    case !property.dataownerInheritable && !property.locationInheritable:
      return BrandPropertyInheritType.BRAND_ONLY;

    case property.dataownerInheritable && property.locationInheritable:
      return BrandPropertyInheritType.DATAOWNERS_AND_LOCATIONS;

    case property.dataownerInheritable:
      return BrandPropertyInheritType.DATAOWNERS;

    case property.locationInheritable:
      return BrandPropertyInheritType.LOCATIONS;
  }
  return '';
};

export const getColumnDefType = (valueType: string) => {
  switch (valueType) {
    case PropertyValueTypes.string:
      return 'string';
    case PropertyValueTypes.boolean:
      return 'boolean';
    case PropertyValueTypes.number:
      return 'number';
    case PropertyValueTypes.date:
      return 'date';
    case PropertyValueTypes.enum:
      return 'singleSelect';
    case PropertyValueTypes.multipleChoice:
      return 'string';
    default:
      return 'string';
  }
};

export const useTransformValueTypesToReadableLabel = (valueTypes: Array<string> | undefined) => {
  const t = useBrandadminTFunction();

  if (!valueTypes) return [];

  return valueTypes.map((valueType) => ({
    name: getValueTypeLabel(valueType, t),
    value: valueType,
  }));
};

export const getOptionLabel = (option: string | KeyGroupOptionType | null) => {
  if (option === null) return '';
  if (typeof option === 'string') return option;
  if (option.inputValue) return option.inputValue;
  return option.keyGroup;
};

export const getFilterOptions = (
  options: KeyGroupOptionType[],
  params: FilterOptionsState<KeyGroupOptionType>,
  addText: string
) => {
  const filtered = keyGroupFilterOptions(options, params);
  const { inputValue } = params;
  const isExisting = options.some((option) => inputValue === option.keyGroup);
  if (inputValue !== '' && !isExisting) {
    filtered.push({
      inputValue,
      keyGroup: `${addText} "${inputValue}"`,
    });
  }
  return filtered;
};

export const propertyValueGetter = (value: string, valueType: string, t: AppTFunction) => {
  switch (valueType) {
    case PropertyValueTypes.string:
      return value;
    case PropertyValueTypes.boolean:
      return value === 'true';
    case PropertyValueTypes.number:
      return value;
    case PropertyValueTypes.date:
      return value ? new Date(value) : '';
    case PropertyValueTypes.enum:
      return value;
    case PropertyValueTypes.multipleChoice:
      return value;
    default:
      return '';
  }
};

const hasNoValue = (value: string) => value === null || value === undefined || value === '';

export const formatValueToString = (value: string, valueType: string) => {
  if (hasNoValue(value)) return ''; // API does not accept null as empty value (interpreted as no changes)
  return value && valueType !== PropertyValueTypes.date ? value.toString() : value;
};

export const renderValue = (property: PropertyDetail, t: AppTFunction) => {
  if (property.valueType === PropertyValueTypes.boolean) return renderBoolean(property.value, t);
  if (property.valueType === PropertyValueTypes.date) return renderDate(property.value);
  return property.value;
};

export const renderBoolean = (value: string, t: AppTFunction) => {
  if (hasNoValue(value)) return '';
  return value === 'true' ? t('common.yes') : t('common.no');
};

export const renderDate = (value: string) => {
  if (hasNoValue(value)) return '';
  return formatDate(value, 'dd/MM/yyyy');
};

export const multipleChoiceSeparator = ';';

export const mapOrgUnitsToTransformedProperties = (
  orgUnits: Array<DataownerBasic | LocationOverview>,
  orgUnitType: InformationModelType,
  property: BrandProperty
): Array<TransformedInheritablePropertyValue> => {
  let parentOrgUnitKey: string | undefined;
  let parentOrgUnitName: string | undefined;

  return orgUnits.map((orgUnit) => {
    // if org unit is a of type location
    if ('dataownerKey' in orgUnit && 'dataownerName' in orgUnit) {
      parentOrgUnitKey = orgUnit.dataownerKey;
      parentOrgUnitName = orgUnit.dataownerName;
    }

    return {
      valueType: property.valueType,
      value: orgUnit.properties?.find((prop) => prop.key === property.key)?.value ?? '',
      orgUnitType: orgUnitType,
      orgUnitName: orgUnit.name,
      orgUnitKey: orgUnit.key,
      ...(orgUnitType === InformationModelType.Location
        ? {
            parentOrgUnitKey,
            parentOrgUnitName,
          }
        : {}),
    };
  });
};
