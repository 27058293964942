import {
  GRID_CHECKBOX_SELECTION_COL_DEF,
  GRID_ROW_GROUPING_SINGLE_GROUPING_FIELD,
  GridColDef,
} from '@mui/x-data-grid-premium';

const getDefaultUntogglebleDataGridFields = [
  'actions',
  GRID_CHECKBOX_SELECTION_COL_DEF.field,
  GRID_ROW_GROUPING_SINGLE_GROUPING_FIELD,
];

export const getTogglableDataGridColumns = (columns: GridColDef[]) => {
  return columns
    .filter((column) => !getDefaultUntogglebleDataGridFields.includes(column.field))
    .map((column) => column.field);
};
