import {
  ListItemButtonProps,
  ListItemTextProps,
  ListItemButton as MuiListItemButton,
  ListItemText as MuiListItemText,
  styled,
} from '@mui/material';
import { PropsWithChildren } from 'react';

const StyledListItemButton = styled(MuiListItemButton)(({ theme }) => ({
  background: theme.palette.background.paper,
  padding: theme.spacing(2, 3),
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'start start',
  alignItems: 'start',
  borderBottom: `1px solid ${theme.palette.grey.A100}`,
  transition: `${theme.transitions.create('background', {
    duration: theme.transitions.duration.short,
    easing: theme.transitions.easing.sharp,
  })}`,

  '&.Mui-selected': {
    background: theme.palette.success[100],
    '&:hover': {
      background: theme.palette.success[100],
    },
  },

  '&:hover, &.Mui-focusVisible': {
    background: `${theme.palette.primary[100]}`,
  },
}));

const StyledListItemText = styled(MuiListItemText)(({ theme }) => ({
  margin: 0,
  width: '100%',

  '&.MuiListItemText-primary': {
    ...theme.typography.body1,
    fontWeight: 700,
  },

  '&.MuiListItemText-secondary': {
    ...theme.typography.body2,
    color: theme.palette.secondary,
    fontWeight: 500,
  },
}));

interface Props extends Pick<ListItemButtonProps, 'selected' | 'style'> {
  primaryText?: ListItemTextProps['primary'];
  secondaryText?: ListItemTextProps['secondary'];
  handleClick: () => void;
}

export default function ListItemButton({
  style,
  primaryText,
  secondaryText,
  handleClick,
  selected,
  children,
}: PropsWithChildren<Props>) {
  return (
    <StyledListItemButton selected={selected} style={style} onClick={handleClick}>
      {(primaryText || secondaryText) && (
        <StyledListItemText
          primary={primaryText}
          primaryTypographyProps={{ variant: 'body1', fontWeight: 700, component: 'div' }}
          secondary={secondaryText}
          secondaryTypographyProps={{ variant: 'body2', fontWeight: 500, component: 'div' }}
        />
      )}
      {children}
    </StyledListItemButton>
  );
}
