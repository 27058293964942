import {
  bademiljoeIconUri,
  bademiljoeImgUri,
  blinkhusIconUri,
  blinkhusImgUri,
  boligpartnerIconUri,
  boligpartnerImgUri,
  bolistIconImgUri,
  bolistLogoImgUri,
  byggerietImgUri,
  byggtorgetIconUri,
  byggtorgetImgUri,
  comfortIconUri,
  comfortImgUri,
  fargerikeIconUri,
  fargerikeLogoImgUri,
  kuloerFaviconUri,
  kuloerIconUri,
  kuloerImgUri,
  mestergruppenIconUri,
  mestergruppenImgUri,
  mesterhusIconUri,
  mesterhusImgUri,
  nordbohusIconUri,
  nordbohusImgUri,
  pilarrImgUri,
  pilarrIconUri,
  pretreIconImgUri,
  pretreImgUri,
  saltdalshyttaIconUri,
  saltdalshyttaImgUri,
  steddyIconUri,
  steddyImgUri,
  systemhusIconUri,
  systemhusImgUri,
  wifaIconUri,
  wifaImgUri,
  xlByggIconUri,
  xlByggImgUri,
} from '@assets/images';
import { BrandStylesConfig } from '@dap-common/types';
import { intranetTheme } from '@shared/styles';

// Various colors used for brand styles
const brandColors = {
  // Default colors for brand, used as fallback. Sets the styles of the BrandSelect button among other things
  // Defined once to avoid duplication
  default: {
    main: intranetTheme.palette.grey[100],
    contrastText: intranetTheme.palette.text.primary,
  },

  // Colors defined in Figma describing the contrast text when the backgorund is a brands main color
  text: {
    light: '#fafafa',
    dark: '#232323',
  },
};

export const brandStylesConfig: BrandStylesConfig = {
  // Generic brand styles
  default: {
    logo: mestergruppenImgUri,
    icon: mestergruppenIconUri,
    favicon: mestergruppenIconUri,
    colors: {
      main: brandColors.default.main,
      contrastText: brandColors.default.contrastText,
    },
  },

  // Specific brand styles
  bademiljoe: {
    logo: bademiljoeImgUri,
    icon: bademiljoeIconUri,
    favicon: bademiljoeIconUri,
    url: 'https://www.bademiljo.no/',
    colors: {
      main: brandColors.default.main,
      contrastText: brandColors.default.contrastText,
    },
  },
  blinkhus: {
    logo: blinkhusImgUri,
    icon: blinkhusIconUri,
    favicon: blinkhusIconUri,
    url: 'http://www.blink-hus.no/',
    colors: {
      main: '#295281',
      contrastText: intranetTheme.palette.common.white,
      brand: {
        main: '#295281',
        contrastText: brandColors.text.light,
      },
    },
  },
  boligpartner: {
    logo: boligpartnerImgUri,
    icon: boligpartnerIconUri,
    favicon: boligpartnerIconUri,
    url: 'https://boligpartner.no/',
    colors: {
      main: '#3a6e89',
      contrastText: intranetTheme.palette.common.white,
    },
  },
  bolist: {
    logo: bolistLogoImgUri,
    icon: bolistIconImgUri,
    favicon: bolistIconImgUri,
    colors: {
      main: 'rgb(255, 94, 10)',
      light: 'rgba(255, 94, 10, 0.20)',
      contrastText: brandColors.default.contrastText,
      brand: {
        main: '#FA4E01',
        contrastText: brandColors.text.dark,
      },
    },
  },
  byggeriet: {
    logo: byggerietImgUri,
    icon: byggerietImgUri,
    favicon: byggerietImgUri,
    url: 'https://byggeriet.no/',
  },
  byggtorget: {
    logo: byggtorgetImgUri,
    icon: byggtorgetIconUri,
    favicon: byggtorgetIconUri,
    url: 'https://www.byggtorget.no/',
    colors: {
      main: 'rgb(0, 107, 45)',
      light: '#C6D7CC',
      contrastText: brandColors.default.contrastText,
      brand: {
        main: '#124932',
        contrastText: brandColors.text.light,
      },
    },
  },
  comfort: {
    logo: comfortImgUri,
    icon: comfortIconUri,
    favicon: comfortIconUri,
    url: 'https://www.comfort.no/',
    colors: {
      main: brandColors.default.main,
      contrastText: brandColors.default.contrastText,
      brand: {
        main: '#E40427',
        contrastText: brandColors.text.light,
      },
    },
  },
  fargerike: {
    logo: fargerikeLogoImgUri,
    icon: fargerikeIconUri,
    favicon: fargerikeIconUri,
    url: 'https://www.fargerike.no/',
    colors: {
      main: brandColors.default.main,
      contrastText: brandColors.default.contrastText,
      brand: {
        main: '#C04D12',
        contrastText: brandColors.text.light,
      },
    },
  },
  kuloer: {
    logo: kuloerImgUri,
    icon: kuloerIconUri,
    favicon: kuloerFaviconUri,
    url: 'https://kulornorge.no/',
    colors: {
      main: brandColors.default.main,
      contrastText: brandColors.default.contrastText,
      brand: {
        main: '#F8981D',
        contrastText: brandColors.text.dark,
      },
    },
  },
  mesterhus: {
    logo: mesterhusImgUri,
    icon: mesterhusIconUri,
    favicon: mesterhusIconUri,
    url: 'https://www.mesterhus.no/',
    colors: {
      main: brandColors.default.main,
      contrastText: brandColors.default.contrastText,
      brand: {
        main: '#CF281F',
        contrastText: brandColors.text.light,
      },
    },
  },
  mestergruppen: {
    logo: mestergruppenImgUri,
    icon: mestergruppenIconUri,
    favicon: mestergruppenIconUri,
  },

  nordbohus: {
    logo: nordbohusImgUri,
    icon: nordbohusIconUri,
    favicon: nordbohusIconUri,
    url: 'https://nordbohus.no/',
    colors: {
      main: '#1F3F5D',
      contrastText: intranetTheme.palette.common.white,
      brand: {
        main: '#1F3F5D',
        contrastText: brandColors.text.light,
      },
    },
  },
  pilarr: {
    logo: pilarrImgUri,
    icon: pilarrIconUri,
    favicon: pilarrIconUri,
  },
  pretre: {
    logo: pretreImgUri,
    icon: pretreIconImgUri,
    favicon: pretreImgUri,
    url: 'https://www.pretre.no/',
  },
  rot: {
    // Steddy
    logo: steddyImgUri,
    icon: steddyIconUri,
    favicon: steddyIconUri,
    colors: {
      main: '#273830',
      contrastText: intranetTheme.palette.common.white,
    },
  },
  saltdalshytta: {
    logo: saltdalshyttaImgUri,
    icon: saltdalshyttaIconUri,
    favicon: saltdalshyttaIconUri,
    url: 'https://www.saltdalshytta.no/',
    colors: {
      main: '#E3E0DA',
      contrastText: brandColors.default.contrastText,
      brand: {
        main: '#FE7060',
        contrastText: brandColors.text.dark,
      },
    },
  },
  systemhus: {
    logo: systemhusImgUri,
    icon: systemhusIconUri,
    favicon: systemhusIconUri,
    url: 'https://www.systemhus.no/',
    colors: {
      main: '#FFF7ED',
      contrastText: brandColors.default.contrastText,
      brand: {
        main: '#CF350D',
        contrastText: brandColors.text.light,
      },
    },
  },
  wifa: {
    logo: wifaImgUri,
    icon: wifaIconUri,
    favicon: wifaIconUri,
    url: 'https://wifa.no/',
  },
  xlbygg: {
    logo: xlByggImgUri,
    icon: xlByggIconUri,
    favicon: xlByggIconUri,
    url: 'https://www.xl-bygg.no/',
    colors: {
      main: '#EBE6DB',
      contrastText: brandColors.default.contrastText,
      brand: {
        main: '#CEBB7B',
        contrastText: brandColors.text.dark,
      },
    },
  },
  xlbyggsverige: {
    logo: xlByggImgUri,
    icon: xlByggIconUri,
    favicon: xlByggIconUri,
    url: 'https://www.xlbygg.se/',
    colors: {
      main: '#EBE6DB',
      contrastText: brandColors.default.contrastText,
      brand: {
        main: '#CEBB7B',
        contrastText: brandColors.text.dark,
      },
    },
  },
};
