import { SvgIcon, SvgIconProps, useTheme } from '@mui/material';

function HelpCentreIcon(props: SvgIconProps) {
  const { palette } = useTheme();
  return (
    <SvgIcon {...props} width="14" height="14" viewBox="0 0 14 14" fill="none">
      <g clipPath="url(#clip0_6383_34905)">
        <path
          opacity="0.4"
          d="M4.35039 5.25H3.9375C2.97117 5.25 2.1875 6.03367 2.1875 7.00219V8.30895C2.1875 9.27773 2.97227 10.0625 3.9375 10.0625H4.375C4.85789 10.0625 5.25 9.66984 5.25 9.18586L5.22539 6.125C5.22539 5.64102 4.85625 5.25 4.35039 5.25ZM10.0625 5.25H9.625C9.14211 5.25 8.75 5.64211 8.75 6.12609V9.18477C8.75 9.67148 9.14102 10.0625 9.625 10.0625H10.0625C11.0288 10.0625 11.8125 9.27773 11.8125 8.30895V7.00273C11.8125 6.03477 11.0277 5.25 10.0625 5.25Z"
          fill={palette.primary.dark}
        />
        <path
          d="M7 0C3.08711 0 0.125316 3.25664 0.00056875 7L0 8.09375C0 8.45742 0.293945 8.75 0.631641 8.75C0.969336 8.75 1.3125 8.45742 1.3125 8.09375V7C1.3125 3.86367 3.86477 1.31797 7 1.31797C10.1363 1.31797 12.6875 3.86367 12.6875 7V10.9375C12.6875 11.5415 12.1978 12.0312 11.5938 12.0312H8.5668C8.33984 11.6402 7.92148 11.375 7.4375 11.375H6.61172C5.97707 11.375 5.39492 11.804 5.27543 12.4275C5.11328 13.2672 5.75313 14 6.53789 14H7.4375C7.92203 14 8.34066 13.7345 8.56789 13.3438H11.5938C12.9227 13.3438 14 12.2664 14 10.9375V7C13.8742 3.25664 10.9129 0 7 0Z"
          fill={palette.primary.main}
        />
      </g>
      <defs>
        <clipPath id="#clip0_6383_34905">
          <rect width="14" height="14" fill="white" />
        </clipPath>
      </defs>
    </SvgIcon>
  );
}

export default HelpCentreIcon;
