import {
  Table,
  TableBody,
  TableCell,
  TableRow,
  styled,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import React from 'react';

interface Props {
  items: Array<{ label: React.ReactNode; value: React.ReactNode }>;
  size?: 'small' | 'medium';
  padding?: 'normal' | 'none';
  typography?: 'body1' | 'body2';
  labelWidth?: number;
}

export default function LabelValueTable({
  items,
  padding = 'normal',
  size = 'small',
  typography = 'body1',
  labelWidth,
}: Props) {
  const { breakpoints } = useTheme();
  const isSmallScreen = useMediaQuery(breakpoints.down('sm'));

  return (
    <Table padding={padding} size={size}>
      <TableBody>
        {items.map(({ label, value }, index) =>
          isSmallScreen ? (
            <>
              <TableRow key={`${label}-${index}-label`}>
                <LabelCell variant="head" typography={typography} labelWidth={labelWidth}>
                  {label}
                </LabelCell>
              </TableRow>
              <TableRow key={`${label}-${index}-value`}>
                <ValueCell typography={typography} sx={{ paddingLeft: 0, paddingTop: 0 }}>
                  {value || '–'}
                </ValueCell>
              </TableRow>
            </>
          ) : (
            <TableRow key={`${label}-${index}`}>
              <LabelCell variant="head" typography={typography} labelWidth={labelWidth}>
                {label}
              </LabelCell>
              <ValueCell typography={typography}>{value || '–'}</ValueCell>
            </TableRow>
          )
        )}
      </TableBody>
    </Table>
  );
}

const LabelCell = styled(TableCell, {
  shouldForwardProp: (prop) => prop !== 'typography' && prop !== 'labelWidth',
})<Pick<Props, 'typography' | 'labelWidth'>>(({ theme, typography, labelWidth }) => ({
  fontSize:
    typography === 'body2' ? theme.typography.body2.fontSize : theme.typography.body1.fontSize,
  width: labelWidth ? `${labelWidth}px` : '1px',
  fontWeight: 700,
  border: 0,
  verticalAlign: 'baseline',
  whiteSpace: 'nowrap',
  paddingLeft: 0,
  paddingRight: theme.spacing(2),
}));

const ValueCell = styled(TableCell, {
  shouldForwardProp: (prop) => prop !== 'typography',
})<Pick<Props, 'typography'>>(({ theme, typography }) => ({
  fontSize:
    typography === 'body2' ? theme.typography.body2.fontSize : theme.typography.body1.fontSize,
  color: theme.palette.text.secondary,
  border: 0,
  verticalAlign: 'baseline',
  paddingX: 0,
}));
