import { forwardRef, PropsWithChildren } from 'react';
import { ButtonProps } from '@mui/material';
import LoadingButton from './LoadingButton';

function TextButtonBase(
  {
    children,
    loading = false,
    ...rest
  }: PropsWithChildren<
    ButtonProps & {
      loading?: boolean;
    }
  >,
  ref: ButtonProps['ref']
) {
  return (
    <LoadingButton
      variant="text"
      ref={ref}
      size="small"
      color="grey"
      sx={{
        fontSize: '14px',
      }}
      {...rest}
    >
      {children}
    </LoadingButton>
  );
}

const TextButton = forwardRef(TextButtonBase);

export default TextButton;
