import { SvgIcon, SvgIconProps, useTheme } from '@mui/material';

function ProffConceptsIcon(props: SvgIconProps) {
  const { palette } = useTheme();
  return (
    <SvgIcon {...props} width="16" height="16" viewBox="0 0 16 16" fill="none">
      <path
        d="M16 6.41563V10H12V8.5C12 8.22188 11.7781 8 11.5 8H10.5C10.2219 8 10 8.22188 10 8.5V10H6V8.5C6 8.22188 5.77812 8 5.5 8H4.5C4.22188 8 4 8.22188 4 8.5V10H0V6.41563C0 6.15 0.105469 5.89625 0.292969 5.70875L1.70703 4.29469C1.89469 4.10625 2.14844 4 2.41406 4H13.5859C13.8516 4 14.1053 4.10547 14.2928 4.29297L15.7069 5.70703C15.8938 5.89375 16 6.15 16 6.41563Z"
        fill={palette.primary.dark}
      />
      <path
        opacity="0.4"
        d="M12 10V11.5C12 11.7773 11.7773 12 11.5 12H10.5C10.2227 12 10 11.7773 10 11.5V10H6V11.5C6 11.7781 5.77812 12 5.5 12H4.5C4.22188 12 4 11.7781 4 11.5V10H0V14C0 14.5506 0.449375 15 1 15H15C15.5506 15 16 14.5506 16 14V10H12ZM5.5 2.5H10.5V4H12V2.5C12 1.67188 11.3281 1 10.5 1H5.5C4.67188 1 4 1.67188 4 2.5V4H5.5V2.5Z"
        fill={palette.primary.dark}
      />
    </SvgIcon>
  );
}

export default ProffConceptsIcon;
