import { Stack, styled, Tooltip, tooltipClasses, TooltipProps } from '@mui/material';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

interface Props {
  title?: TooltipProps['title'];
  placement?: TooltipProps['placement'];
  children: TooltipProps['children'];
}

const StyledTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.warning.A100,
    color: theme.palette.common.black,
    border: `1px solid ${theme.palette.warning[900]}`,
    padding: theme.spacing(0.5, 2),
    fontWeight: 600,
    boxShadow: theme.shadows[2],
    borderRadius: theme.spacing(1),
  },
}));

export default function AlertTooltip({ title, placement = 'bottom', children }: Props) {
  return (
    <StyledTooltip
      title={
        <Stack direction="row" justifyContent="center" alignItems="center" gap={1} pr={0.5}>
          <InfoOutlinedIcon color="warning" sx={{ fontSize: '16px' }} />
          {title}
        </Stack>
      }
      placement={placement}
    >
      {children}
    </StyledTooltip>
  );
}
