import { Typography } from '@mui/material';
import { PortableText } from '@portabletext/react';
import { useMemo } from 'react';
import type { PortableTextBlock } from '@portabletext/types';
import { richTextPortableComponents } from './richTextPortableComponent';

interface Props {
  blocks: PortableTextBlock[];
}

export function RichTextRenderer({ blocks }: Props): JSX.Element {
  // Strip empty paragraphs
  const filteredBlocks = useMemo(
    () =>
      blocks &&
      blocks.filter(
        ({ children, _type }) =>
          _type !== 'block' || !!children?.some(({ text, _type }) => _type !== 'span' || !!text)
      ),
    [blocks]
  );

  return (
    <Typography variant="body1" component="section">
      <PortableText value={filteredBlocks} components={richTextPortableComponents} />
    </Typography>
  );
}
