import {
  Avatar,
  Box,
  IconButton,
  IconButtonProps,
  ListItem,
  ListItemAvatar,
  ListProps,
  List as MuiList,
  SxProps,
  Theme,
  Typography,
  styled,
} from '@mui/material';
import { usePopupElement } from '@shared/hooks';
import { MouseEvent } from 'react';
import { Popover } from '../../Popover';

interface Props extends Pick<ListProps, 'dense' | 'disablePadding'> {
  items: Array<React.ReactNode>;
  max?: number;
  title?: string;
  row?: boolean;
  itemWidth?: string;
}

export default function LimitedList({
  items,
  max = 3,
  dense = true,
  disablePadding = true,
  title,
  row = false,
  itemWidth = 'auto',
}: Props) {
  const totalItems = items.length;
  const maxItems = max || totalItems;
  const hiddenItems = totalItems - maxItems;
  const { triggerElementProps, menuElementProps } = usePopupElement();

  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    triggerElementProps.onClick(event);
  };

  return (
    <>
      <ListItems
        dense={dense}
        disablePadding={disablePadding}
        items={items.slice(0, maxItems)}
        hiddenItems={hiddenItems}
        maxItems={maxItems}
        row={row}
        width={itemWidth}
        iconButtonProps={{ ...triggerElementProps, onClick: handleClick }}
      />

      <div
        onClick={(event) => {
          event.stopPropagation();
        }}
      >
        <Popover {...menuElementProps} handleClose={menuElementProps.onClose} title={title}>
          <Box minWidth="240px" maxWidth={row ? '240px' : 'auto'}>
            <ListItems
              dense={dense}
              disablePadding={disablePadding}
              items={items}
              hiddenItems={0}
              maxItems={items.length}
              row={row}
              iconButtonProps={{ ...triggerElementProps, onClick: handleClick }}
            />
          </Box>
        </Popover>
      </div>
    </>
  );
}

interface ListItemProps extends Pick<Props, 'items' | 'dense' | 'disablePadding' | 'row'> {
  hiddenItems: number;
  width?: string;
  maxItems: number;
  iconButtonProps: IconButtonProps;
}

const ListItems = ({
  items,
  dense,
  disablePadding,
  row = false,
  width = 'auto',
  hiddenItems,
  maxItems,
  iconButtonProps,
}: ListItemProps) => {
  return (
    <List dense={dense} disablePadding={disablePadding} row={row}>
      {items.map((item, index) => (
        <ListItem
          key={index}
          disableGutters
          sx={row ? { width } : null}
          secondaryAction={
            hiddenItems && index === maxItems - 1 ? (
              <ShowAllItemsButton iconButtonProps={iconButtonProps} hiddenItemCount={hiddenItems} />
            ) : null
          }
        >
          {item}
        </ListItem>
      ))}
    </List>
  );
};

const ShowAllItemsButton = ({
  iconButtonProps,
  hiddenItemCount,
}: {
  iconButtonProps: IconButtonProps;
  hiddenItemCount: number;
}) => (
  <ListItemAvatar>
    <IconButton
      {...iconButtonProps}
      sx={[
        { ...{ ml: 1 } },
        ...(Array.isArray(iconButtonProps.sx) ? iconButtonProps.sx : [iconButtonProps.sx]),
      ]}
    >
      <Avatar sx={avatarSx}>
        <Typography variant="body3">+{hiddenItemCount}</Typography>
      </Avatar>
    </IconButton>
  </ListItemAvatar>
);

const List = styled(MuiList, {
  shouldForwardProp: (prop) => prop !== 'row',
})<{ row: boolean }>(({ theme, row }) => ({
  ...(row && {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    rowGap: theme.spacing(0.25),
    columnGap: theme.spacing(1),
    width: '100%',
  }),
  ...theme.typography.body2,
  fontWeight: 600,
}));

const avatarSx: SxProps<Theme> = ({ palette }) => ({
  width: 24,
  height: 24,
  bgcolor: palette.info.main,
  color: palette.text.primary,
});
