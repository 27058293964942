export enum LanguageCode {
  Norwegian = 'nb',
  Norwegian_Nynorsk = 'nn',
  Swedish = 'sv',
  English = 'en',
}

export enum LanguageCapitalLettersCode {
  Norwegian = 'NO',
  Swedish = 'SE',
  English = 'EN',
}
