import { useTheme } from '@mui/material';

const RadioUncheckedIcon = () => {
  const { palette } = useTheme();

  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
      <g filter="url(#filter0_i_8509_126653)" transform="translate(2 2)">
        <circle cx="10" cy="10" r="8" stroke={palette.grey.A400} strokeWidth="2" />
      </g>
    </svg>
  );
};

export default RadioUncheckedIcon;