import { yupResolver } from '@hookform/resolvers/yup';
import React, { FunctionComponent } from 'react';
import { FieldValues, FormProvider, useForm, UseFormProps } from 'react-hook-form';
import * as yup from 'yup';

interface FormProps<T extends FieldValues> extends UseFormProps<T> {
  schema: yup.AnyObjectSchema;
}

interface ComponentProps {
  children: React.ReactNode;
}

const UniversalFormProvider = <T extends FieldValues>({
  children,
  schema,
  shouldUnregister = false,
  mode = 'onSubmit',
  reValidateMode = 'onChange',
  ...restProps
}: FormProps<T> & ComponentProps) => {
  const formMethods = useForm({
    resolver: yupResolver(schema),
    shouldUnregister,
    mode,
    reValidateMode,
    ...restProps,
  });

  return <FormProvider {...formMethods}>{children}</FormProvider>;
};

export const withFormProvider =
  <FormT extends FieldValues, OwnPropsT extends object>(
    WrappedComponent: React.ComponentType<OwnPropsT>,
    getFormProps: (ownProps: OwnPropsT) => FormProps<FormT>
  ): FunctionComponent<OwnPropsT> =>
  (ownProps) => {
    return (
      <UniversalFormProvider {...getFormProps(ownProps)}>
        <WrappedComponent {...ownProps} />
      </UniversalFormProvider>
    );
  };

export default UniversalFormProvider;
