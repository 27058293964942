import sharedNo from './no/shared.json';
import sharedSe from './se/shared.json';
import sharedEn from './en/shared.json';

const defaultResources = {
  shared: sharedNo,
};

export type SharedResources = typeof defaultResources;

export type SharedLanguageResources = {
  no: SharedResources;
  se: SharedResources;
  en: SharedResources;
};

export const sharedLanguageResources: SharedLanguageResources = {
  no: defaultResources,
  se: {
    shared: sharedSe,
  },
  en: {
    shared: sharedEn,
  },
};