import { Stack, Typography, Button, useTheme, Card } from '@mui/material';
import { Link } from 'react-router-dom';
import PreviewSanityImage from '../../previewContent/PreviewSanityImage';
import { SanityImageSource } from '@sanity/image-url/lib/types/types';

interface Props {
  title: string;
  intro?: string;
  image: SanityImageSource;
  url: string;
  imagePlacement: 'left' | 'right';
  brandKey: string;
}

export function FeaturedArticle({ title, intro, image, url, imagePlacement }: Props) {
  const { palette, breakpoints } = useTheme();

  return (
    <Card sx={{ padding: 0 }}>
      <Stack
        minHeight={360}
        sx={{
          flexDirection: imagePlacement === 'left' ? 'row-reverse' : 'row',
          gap: 2,
          [breakpoints.down('md')]: {
            flexDirection: 'column-reverse',
          },
        }}
      >
        <Stack
          padding={6}
          spacing={3}
          justifyContent="center"
          alignItems="flex-start"
          sx={{
            flex: '1 0 50%',
          }}
        >
          <Typography
            variant="h2"
            sx={{
              textWrap: 'balance',
              hyphens: 'auto',
            }}
          >
            {title}
          </Typography>
          {intro && (
            <Typography variant="h6" component="p" fontWeight={500} lineHeight={28 / 20}>
              {intro}
            </Typography>
          )}
          <Button
            variant="contained"
            color={palette?.brand ? 'brand' : 'secondary'}
            to={url}
            component={Link}
          >
            Les mer
          </Button>
        </Stack>

        <Stack
          sx={{
            flex: '1 0 50%',
            position: 'relative',
            [breakpoints.down('md')]: {
              aspectRatio: 16 / 9,
            },
          }}
        >
          <PreviewSanityImage
            noBorderRadius
            height={360}
            image={image}
            sx={{
              position: 'absolute',
              height: '100%',
            }}
          />
        </Stack>
      </Stack>
    </Card>
  );
}
