import { forwardRef } from 'react';
import { Avatar, Badge, BadgeProps, Box, styled } from '@mui/material';

interface Props {
  username: string;
  userPicture?: string;
}

export const SidebarUserAvatar = forwardRef(({ username, userPicture }: Props, ref) => {
  return (
    <Box
      ref={ref}
      display="flex"
      justifyContent="center"
      alignItems="center"
      borderRadius="50%"
      width={55}
      height={55}
    >
      <StyledBadge variant="dot" color="success">
        <Avatar
          alt={username}
          src={userPicture}
          sx={{
            width: 40,
            height: 40,
          }}
        />
      </StyledBadge>
    </Box>
  );
});

const StyledBadge = styled(Badge)<BadgeProps>(() => ({
  '& .MuiBadge-badge': {
    height: '10px',
    width: '10px',
    right: 7,
    top: 5,
  },
}));
