const RadioCheckedIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
      <circle cx="12" cy="12" r="10" fill="url(#paint0_linear_8509_126647)" />
      <g filter="url(#filter0_d_8509_126647)">
        <circle cx="12" cy="12" r="5" fill="white" />
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_8509_126647"
          x1="20.155"
          y1="19.2063"
          x2="-2.84595"
          y2="14.0588"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#512AFC" />
          <stop offset="0.327149" stopColor="#623FFC" />
          <stop offset="0.636347" stopColor="#7455FD" />
          <stop offset="1" stopColor="#856AFD" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default RadioCheckedIcon;
