import { addMinutes, isAfter } from 'date-fns';
import { MGAuthToken } from '@shared/types';
import { jwtDecode } from 'jwt-decode';

export const hasExpired = (token: string, offsetMinutes = 60) => {
  const now = new Date();
  const addTime = addMinutes(now, offsetMinutes);
  const decodedToken = jwtDecode<MGAuthToken>(token);
  if (decodedToken.exp) {
    const tokenDate = new Date(decodedToken.exp * 1000);
    return isAfter(addTime, tokenDate);
  }
  return false;
};
