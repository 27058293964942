import { SvgIcon, SvgIconProps } from '@mui/material';

function DownloadIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props} width="16" height="15" viewBox="0 0 16 15" fill="none">
      <path
        d="M14.4375 8.625H11.8945L13.1797 7.36719C14 6.54688 13.3984 5.125 12.25 5.125H10.5V2.0625C10.5 1.35156 9.89844 0.75 9.1875 0.75H6.5625C5.82422 0.75 5.25 1.35156 5.25 2.0625V5.125H3.5C2.32422 5.125 1.72266 6.54688 2.57031 7.36719L3.82812 8.625H1.3125C0.574219 8.625 0 9.22656 0 9.9375V13.4375C0 14.1758 0.574219 14.75 1.3125 14.75H14.4375C15.1484 14.75 15.75 14.1758 15.75 13.4375V9.9375C15.75 9.22656 15.1484 8.625 14.4375 8.625ZM3.5 6.4375H6.5625V2.0625H9.1875V6.4375H12.25L7.875 10.8125L3.5 6.4375ZM14.4375 13.4375H1.3125V9.9375H5.14062L6.94531 11.7422C7.4375 12.2617 8.28516 12.2617 8.77734 11.7422L10.582 9.9375H14.4375V13.4375ZM12.0312 11.6875C12.0312 12.0703 12.3047 12.3438 12.6875 12.3438C13.043 12.3438 13.3438 12.0703 13.3438 11.6875C13.3438 11.332 13.043 11.0312 12.6875 11.0312C12.3047 11.0312 12.0312 11.332 12.0312 11.6875Z"
        fill="#3C3532"
      />
    </SvgIcon>
  );
}

export default DownloadIcon;