import { ButtonProps } from '@mui/material';
import { ComponentProps, ElementType, forwardRef, PropsWithChildren } from 'react';
import LoadingButton, { LoadingButtonProps } from './LoadingButton';

type Props<C extends ElementType> = Omit<LoadingButtonProps<C>, 'variant' | 'loading'> & {
  loading?: boolean;
  component?: C;
} & ComponentProps<C>;

function OutlinedButtonBase<C extends ElementType = 'button'>(
  { children, loading = false, ...rest }: PropsWithChildren<Props<C>>,
  ref: ButtonProps['ref']
) {
  return (
    <LoadingButton variant="outlined" loading={loading} ref={ref} {...rest}>
      {children}
    </LoadingButton>
  );
}

const OutlinedButton = forwardRef(OutlinedButtonBase);

export default OutlinedButton;
