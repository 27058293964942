import { SvgIcon, SvgIconProps } from '@mui/material';

export default function SwedishFlagRoundedIcon(props: SvgIconProps) {
  return (
    <SvgIcon width="202" height="200" viewBox="0 0 202 200" fill="none" {...props}>
      <ellipse cx="101" cy="100" rx="101" ry="100" fill="#FECC00" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1 84C5.5239 56.2263 21.633 32.2774 44.2779 17.1387V84H1ZM1.16817 117C5.91217 144.348 21.9052 167.905 44.2779 182.861V117H1.16817ZM77.7034 117V197.342C85.1832 199.08 92.9829 200 101 200C151.071 200 192.656 164.131 200.832 117H77.7034ZM201 84C193.243 36.3772 151.425 0 101 0C92.9829 0 85.1832 0.919585 77.7034 2.65774V84H201Z"
        fill="#006AA7"
      />
    </SvgIcon>
  );
}
