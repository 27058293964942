import { SvgIcon, SvgIconProps } from '@mui/material';

export default function NorwegianFlagRoundedIcon(props: SvgIconProps) {
  return (
    <SvgIcon
      width="250.000000pt"
      height="250.000000pt"
      viewBox="0 0 250.000000 250.000000"
      preserveAspectRatio="xMidYMid meet"
      {...props}
    >
      <g
        transform="translate(0.000000,250.000000) scale(0.100000,-0.100000)"
        fill="#000000"
        stroke="none"
      >
        <path
          fill="#ba0c30"
          d="M1138 2493 l-38 -4 0 -462 0 -462 675 3 c577 2 675 4 675 17 0 7 -12
47 -26 87 -124 344 -407 628 -749 751 -152 54 -402 87 -537 70z"
        />
        <path
          fill="#02205b"
          d="M867 2440 c-31 -10 -98 -37 -147 -61 l-90 -42 0 -464 0 -463 -309 0
-308 0 -7 -46 c-8 -60 -8 -168 0 -228 l7 -46 308 0 309 0 0 -463 0 -464 98
-46 c77 -36 188 -77 209 -77 2 0 3 236 3 525 l0 525 774 0 773 0 7 46 c8 60 8
168 0 228 l-7 46 -773 0 -774 0 0 525 c0 289 -3 525 -7 524 -5 0 -34 -8 -66
-19z"
        />
        <path
          fill="#ba0c30"
          d="M370 2130 c-106 -105 -190 -224 -249 -351 -41 -89 -78 -196 -70 -203
3 -3 97 -6 209 -6 l205 -2 3 167 c1 91 1 239 0 328 l-3 162 -95 -95z"
        />
        <path
          fill="#ba0c30"
          d="M253 932 c-167 -2 -203 -5 -203 -17 0 -22 49 -153 82 -220 56 -111
142 -229 238 -325 l95 -95 3 324 c1 179 -1 327 -5 330 -5 3 -99 5 -210 3z"
        />
        <path
          fill="#ba0c30"
          d="M1100 473 l0 -461 76 -7 c100 -10 274 8 392 40 340 91 615 309 781
617 42 76 101 226 101 253 0 13 -92 15 -675 17 l-675 3 0 -462z"
        />
      </g>
    </SvgIcon>
  );
}
