import CloseIcon from '@mui/icons-material/Close';
import { IconButton, Popover as MuiPopover, PopoverProps, Stack, Typography } from '@mui/material';
import { PropsWithChildren, ReactNode } from 'react';

interface Props
  extends Pick<
    PopoverProps,
    | 'id'
    | 'open'
    | 'anchorEl'
    | 'elevation'
    | 'anchorOrigin'
    | 'transformOrigin'
    | 'transitionDuration'
    | 'action'
    | 'slotProps'
  > {
  title?: ReactNode;
  showCloseButton?: boolean;
  handleClose: () => void;
}

// relevant hook usePopupElement

export default function Popover({
  title,
  showCloseButton = true,
  handleClose,
  children,
  ...rest
}: PropsWithChildren<Props>) {
  return (
    <MuiPopover {...rest} onClose={handleClose}>
      {(title || showCloseButton) && (
        <Stack
          direction="row"
          justifyContent={title ? 'space-between' : 'end'}
          alignItems="center"
          mb={1}
        >
          {title && (
            <Typography component="div" variant="body1" fontWeight={700}>
              {title}
            </Typography>
          )}
          {showCloseButton && (
            <IconButton edge="end" size="small" onClick={handleClose}>
              <CloseIcon />
            </IconButton>
          )}
        </Stack>
      )}
      {children}
    </MuiPopover>
  );
}
