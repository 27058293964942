import CheckCircleOutlinedIcon from '@mui/icons-material/CheckCircleOutlineOutlined';
import ErrorOutlineRoundedIcon from '@mui/icons-material/ErrorOutlineRounded';
import { Box, FormHelperText } from '@mui/material';
import { PropsWithChildren } from 'react';

interface Props {
  id: string;
  status: 'error' | 'success' | 'info';
}

export default function HelperText({ id, status, children }: PropsWithChildren<Props>) {
  return (
    <Box display="flex">
      <Box mt={1} mr={1}>
        {status === 'info' && <ErrorOutlineRoundedIcon color="action" fontSize="small" />}
        {status === 'error' && <ErrorOutlineRoundedIcon color="warning" fontSize="small" />}
        {status === 'success' && <CheckCircleOutlinedIcon color="success" fontSize="small" />}
      </Box>

      <Box>
        <FormHelperText
          id={id}
          {...(status === 'success' && { color: 'success.main' })}
          {...(status === 'info' && { color: 'text.secondary' })}
          error={status === 'error'}
        >
          {children}
        </FormHelperText>
      </Box>
    </Box>
  );
}
