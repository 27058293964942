import { styled, SvgIcon, SvgIconProps } from '@mui/material';

export interface RotatableSvgIconProps extends Omit<SvgIconProps, 'orientation'> {
  orientation?: 'left' | 'right' | 'up' | 'down';
}

export const RotatableSvgIcon = styled(SvgIcon)<RotatableSvgIconProps>(
  ({ theme, orientation = 'up' }) => {
    return {
      transition: theme.transitions.create('transform', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.shorter,
      }),
      transform:
        orientation === 'down'
          ? 'rotate(180deg)'
          : orientation === 'left'
          ? 'rotate(-90deg)'
          : orientation === 'right'
          ? 'rotate(90deg)'
          : undefined,
    };
  }
);
