import { Dialog as MuiDialog, DialogProps as MuiDialogProps, IconButton, styled } from '@mui/material';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import { ForwardedRef, forwardRef } from 'react';

export interface DialogProps extends MuiDialogProps {
  onClose: () => void;
  closeOnBackdropClick?: boolean;
}

const CloseButton = styled(IconButton)(({ theme }) => ({
  position: 'absolute',
  right: '-14px',
  top: '-14px',
  width: '32px',
  height: '32px',
  boxShadow: theme.shadows[5],
  background: theme.palette.background.paper,
  ':hover': {
    color: theme.palette.common.white,
    background: theme.palette.grey[900],
  },
}));

const Dialog = forwardRef((props: DialogProps, ref: ForwardedRef<HTMLDivElement>) => {
  const { onClose, closeOnBackdropClick = true, children, ...rest } = props;

  const handleClose = (_event: object, reason: 'backdropClick' | 'escapeKeyDown') => {
    if (reason === 'backdropClick' && !closeOnBackdropClick) return;
    onClose();
  };

  return (
    <MuiDialog onClose={handleClose} {...rest} ref={ref}>
      <CloseButton onClick={onClose} sx={{ zIndex: 1 }}>
        <CloseRoundedIcon fontSize={'small'} />
      </CloseButton>
      {children}
    </MuiDialog>
  );
});

export default Dialog;
