import { SvgIcon, SvgIconProps } from '@mui/material';

export function BreadcrumbChevron(props: SvgIconProps) {
  return (
    <SvgIcon
      {...props}
      viewBox="0 0 6 9"
      fill="none"
      sx={{
        width: 11,
        height: 11,
      }}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.292893 0.292893C-0.097631 0.683417 -0.097631 1.31658 0.292893 1.70711L3.08579 4.5L0.292893 7.29289C-0.097631 7.68342 -0.097631 8.31658 0.292893 8.70711C0.683417 9.09763 1.31658 9.09763 1.70711 8.70711L5.20711 5.20711C5.59763 4.81658 5.59763 4.18342 5.20711 3.79289L1.70711 0.292893C1.31658 -0.0976311 0.683417 -0.0976311 0.292893 0.292893Z"
        fill="#777E91"
      />
    </SvgIcon>
  );
}
