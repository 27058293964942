import { Button, ButtonProps, CircularProgress } from '@mui/material';
import { ComponentProps, ElementType, forwardRef } from 'react';

export type LoadingButtonProps<C extends ElementType> = Pick<
  ButtonProps,
  | 'children'
  | 'disabled'
  | 'fullWidth'
  | 'href'
  | 'startIcon'
  | 'sx'
  | 'LinkComponent'
  | 'onClick'
  | 'disableElevation'
> & {
  loading?: boolean;
  size?: 'small' | 'large';
  color?: 'primary' | 'secondary' | 'warning' | 'grey';
  variant?: 'contained' | 'outlined';
  component?: C;
} & ComponentProps<C>;

function LoadingButtonBase<C extends ElementType>(
  { children, loading = false, size = 'large', disabled = false, ...rest }: LoadingButtonProps<C>,
  ref: ButtonProps['ref']
) {
  return (
    <Button
      {...rest}
      startIcon={loading ? <CircularProgress color="inherit" size={16} /> : rest.startIcon}
      disabled={disabled || loading}
      size={size}
      ref={ref}
    >
      {children}
    </Button>
  );
}

const LoadingButton = forwardRef(LoadingButtonBase);

export default LoadingButton;
