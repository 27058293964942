import { Color } from '@mui/material';

export const mgPrisBlue: Color = {
  50: '#e3ebff',
  100: '#b3c4ff',
  200: '#829dfd',
  300: '#5175fb',
  400: '#244ef9',
  500: '#315CF5',
  600: '#0A39E0',
  700: '#0331D0',
  800: '#0530C9',
  900: '#02196B',
  A100: '#F0F4FF',
  A200: '#F4F4F6',
  A400: '',
  A700: '',
};
