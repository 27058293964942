import { FormControlProps, styled } from '@mui/material';
import { PropsWithChildren } from 'react';

const Wrapper = styled('div', {
  shouldForwardProp: (prop) => prop !== 'labelDirection' && prop !== 'fullWidth',
})<Props>(({ theme: { breakpoints, spacing }, labelDirection }) => ({
  ...(labelDirection === 'row' && {
    [breakpoints.up('sm')]: {
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'wrap',
      gap: spacing(1),
    },
  }),
}));

interface Props extends FormControlProps {
  labelDirection: 'row' | 'column';
}

export default function FormControlWrapper({
  labelDirection,
  children,
  ...rest
}: PropsWithChildren<Props>) {
  return (
    <Wrapper labelDirection={labelDirection} {...rest}>
      {children}
    </Wrapper>
  );
}
