import { forwardRef } from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

const CopyIcon = forwardRef<SVGSVGElement, SvgIconProps>((props, ref) => {
  return (
    <SvgIcon width="22" height="21" viewBox="0 0 22 21" fill="none" {...props} ref={ref}>
      <path
        d="M15.4567 8.74902H10.0062C9.33721 8.74902 8.79492 9.29145 8.79492 9.96056V15.4125C8.79492 16.0816 9.33721 16.624 10.0062 16.624H15.4567C16.1256 16.624 16.6679 16.0816 16.6679 15.4125V9.96056C16.6679 9.29145 16.1256 8.74902 15.4567 8.74902Z"
        stroke="currentColor"
        strokeWidth="1.31217"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="none"
      />
      <path
        d="M6.23677 12.251H5.63115C5.30992 12.251 5.00183 12.1233 4.77468 11.8961C4.54753 11.6689 4.41992 11.3608 4.41992 11.0394V5.58752C4.41992 5.2662 4.54753 4.95804 4.77468 4.73083C5.00183 4.50362 5.30992 4.37598 5.63115 4.37598H11.0817C11.4029 4.37598 11.711 4.50362 11.9382 4.73083C12.1653 4.95804 12.2929 5.2662 12.2929 5.58752V6.19328"
        stroke="currentColor"
        strokeWidth="1.31217"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="none"
      />
    </SvgIcon>
  );
});

export default CopyIcon;
