import { Typography, Card, Stack, useTheme, Box, CardMedia } from '@mui/material';
import type { PortableTextBlock } from '@portabletext/types';
import { PortableText } from '@portabletext/react';

interface TopicHeaderProps {
  title: string;
  text?: PortableTextBlock[];
  imageUrl?: string;
}

export function TopicHeader({ title, text, imageUrl }: TopicHeaderProps) {
  const { breakpoints } = useTheme();

  if (!text && !imageUrl) {
    return null;
  }

  return (
    <Box sx={{ padding: 0 }}>
      <Stack
        minHeight={360}
        sx={{
          flexDirection: 'row',
          gap: 2,
          [breakpoints.down('md')]: {
            flexDirection: 'column',
          },
        }}
      >
        <Card
          sx={{
            flex: '1 0 0',
            display: 'flex',
            [breakpoints.down('md')]: {
              flexBasis: 'auto',
            },
          }}
        >
          <Stack
            padding={6}
            spacing={3}
            flexGrow={1}
            justifyContent="center"
            alignItems="flex-start"
            sx={{
              [breakpoints.down('md')]: {
                padding: 2,
              },
            }}
          >
            <Typography
              variant="h2"
              component="h1"
              sx={{
                hyphens: 'auto',
                textWrap: 'balance',
              }}
            >
              {title}
            </Typography>
            {text && (
              <Typography
                variant="h6"
                component="div"
                fontWeight={500}
                lineHeight={28 / 20}
                maxWidth="md"
              >
                <PortableText value={text} />
              </Typography>
            )}
          </Stack>
        </Card>

        {imageUrl && (
          <Card
            sx={{
              padding: 0,
              flex: '1 0 0',
              position: 'relative',
              display: 'grid',
              borderRadius: 2,
              placeItems: 'center',
              [breakpoints.down('md')]: {
                aspectRatio: 16 / 9,
                flexBasis: 'auto',
              },
            }}
          >
            <CardMedia
              component="img"
              image={imageUrl}
              alt=""
              sx={{
                position: 'relative',
                height: '100%',
                width: '100%',
                objectFit: 'cover',
              }}
            />
          </Card>
        )}
      </Stack>
    </Box>
  );
}
