export type Country = 'SE' | 'NO' | 'FI';

export enum CountryCode {
  Norway = 'NO',
  Sweden = 'SE',
  Finland = 'FI',
  England = 'EN',
}

export enum CountryName {
  NO = 'Norge',
  SE = 'Sverige',
  FI = 'Finland',
}
