import { Country, CountryCode, LanguageCapitalLettersCode } from '@shared/types';
import {
  NoMansLandFlagIcon,
  NorwegianFlagRoundedIcon,
  SwedishFlagRoundedIcon,
  UKFlagRoundedIcon,
} from '@shared/custom-icons';
import { SxProps, Theme } from '@mui/material';
import { useMemo } from 'react';

interface Props {
  size?: string;
  country?: LanguageCapitalLettersCode | CountryCode | Country;
  sx?: Omit<SxProps<Theme>, 'width' | 'height'>;
}

const Flag = ({ country, size = '18px', sx }: Props) => {
  const sizedSx = useMemo(
    () => ({
      height: size,
      width: size,
      ...sx,
    }),
    [sx, size]
  );
  switch (country) {
    case LanguageCapitalLettersCode.Norwegian:
      return <NorwegianFlagRoundedIcon data-test-id="norwegianFlag" sx={sizedSx} />;
    case LanguageCapitalLettersCode.Swedish:
      return <SwedishFlagRoundedIcon data-test-id="swedishFlag" sx={sizedSx} />;
    case LanguageCapitalLettersCode.English:
      return <UKFlagRoundedIcon data-test-id="ukFlag" sx={sizedSx} />;
    default:
      return <NoMansLandFlagIcon data-test-id="noMansFlag" sx={sizedSx} />;
  }
};

export default Flag;
