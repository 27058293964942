/* For an element that modify another element or component, e.g. tab or button */
export const a11yProps = (prefixId: string, value: string | number) => {
  return {
    id: getElementId(prefixId, value),
    'aria-controls': getControlledElementId(prefixId, value),
  };
};

/* For an element or component that is **modified** by another element, e.g. tab panel */
export const controlledElementA11yProps = (prefixId: string, value: string | number) => {
  return {
    id: getControlledElementId(prefixId, value),
    'aria-labelledby': getElementId(prefixId, value),
  };
};

const getElementId = (prefixId: string, value: string | number) => `${prefixId}-${value}`;
const getControlledElementId = (prefixId: string, value: string | number) =>
  `${prefixId}-C-${value}`;
