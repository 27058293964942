import groq from 'groq';
import { LegacyBannerType, BlockType, ObjectType, ModuleType } from '@dap-sanity/types';
import { proffModuleQuery } from './moduleQueries';
import { customLinkQuery } from './fieldQueries';

export const newsListBlockQuery = groq`
  title,
  limit,
  tags,
  articles
`;

export const eventListBlockQuery = groq`
  title,
  source,
  maxItems
`;

export const legacyBannerBlockQuery = groq`
  variant[]{
    _type,
    _key,
    _type == "${LegacyBannerType.Image}" => {
      "image": image.asset->
    },
    _type == "${LegacyBannerType.MGA}" => {
      "order_module": *[
        _type == "${ModuleType.orderModule}"
        && coalesce(^.^.^.^.brand, ^.^.^.brand) in brand
        && show
      ][0]{
        "slug": module_slug.current
      }
    },
    // Fetches the first referenced proff module from the brand configuration
    // NOTE - This assumes there is only added one proff module to the brand configuration
    _type == "legacyProffBanner" => {
      "proff_module": *[
        _type == "brandConfiguration"
        && coalesce(^.^.^.^.brand, ^.^.^.brand) == brand
        && !(_id in path("drafts.*"))
      ][]{
        "proffModuleData": modules[_type match "proff_module"][0]->{
          show,
          ${proffModuleQuery}
        }
      }[proffModuleData.show][0]{ // Ensure we only fetch the module if it's visible
          ...
      }.proffModuleData
    }
  }
`;

export const featuredArticleBlockQuery = groq`
  article,
  imagePlacement,
  title,
  intro,
  image
`;

export const serviceMessageBlockQuery = groq`
  severity,
  title,
  line_1,
  line_2
`;

export const linkListBlockQuery = groq`
  title,
  palette,
  links[]{
    title,
    subtitle,
    url,
    "link": link[0]{
      ${customLinkQuery}
    },
    "image": image.asset->
  }
`;

export const personListBlockQuery = groq`
  title,
  people[]->{
    name,
    email,
    phone,
    jobtitle,
    "image": image.asset->
  }
`;

export const richTextBlockQuery = groq`
  title,
  body[]{
    ...,
    markDefs[]{
      ...,
      _type == "${ObjectType.CustomLink}" => {
        link[0]{
          ${customLinkQuery}
        }
      },
    }
  }
`;

export const textBlockQuery = groq`
  title,
  palette,
  callToAction {
    linkText,
    link[0]{
      ${customLinkQuery}
    }
  },
  textBlock[]{
    ...,
    markDefs[]{
      ...,
      _type == "${ObjectType.CustomLink}" => {
        link[0]{
          ${customLinkQuery}
        }
      },
    }
  }
`;

export const videoBlockQuery = groq`
  title,
  vimeoId,
  poster,
  muted,
  loop,
  autoPlay
`;

export const contentBlocksQuery = groq`
  _type,
  _key,
  _type == "${BlockType.EventList}" => {
    ${eventListBlockQuery}
  },
  _type == "${BlockType.FeaturedArticle}" => {
    ${featuredArticleBlockQuery}
  },
  _type == "${BlockType.Leads}" => {},
  _type == "${BlockType.LegacyBanner}" => {
    ${legacyBannerBlockQuery}
  },
  _type == "${BlockType.LinkList}" => {
    ${linkListBlockQuery}
  },
  _type == "${BlockType.NewsList}" => {
    ${newsListBlockQuery}
  },
  _type == "${BlockType.PersonList}" => {
    ${personListBlockQuery}
  },
  _type == "${BlockType.RichText}" => {
    ${richTextBlockQuery}
  },
  _type == "${BlockType.ServiceMessage}" => {
    ${serviceMessageBlockQuery}
  },
  _type == "${BlockType.Text}" => {
    ${textBlockQuery}
  },
  _type == "${BlockType.Video}" => {
    ${videoBlockQuery}
  },
  _type == "${BlockType.WorkplaceFeed}" => {}
`;
