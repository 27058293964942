import { Typography, Box, SxProps, Theme } from '@mui/material';
import { Breadcrumbs } from '@shared/components';
import { GoBackLink } from '@dap-common/ui';

export interface Breadcrumb {
  label: string | React.ReactNode;
  href?: string;
  relativePath?: boolean;
}

interface Props {
  breadcrumbs?: Breadcrumb[];
  header?: string;
  sx?: SxProps<Theme>;
  backLink?: string;
}

export default function SanityPageHeader({ breadcrumbs, header, sx, backLink }: Props) {
  return (
    <Box sx={sx}>
      <GoBackLink href={backLink} />
      {breadcrumbs && <Breadcrumbs items={breadcrumbs} />}

      {!!header && (
        <Typography variant="h2" component="h1" sx={{ mt: 5 }}>
          {header}
        </Typography>
      )}
    </Box>
  );
}
