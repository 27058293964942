import { SvgIcon, SvgIconProps } from '@mui/material';

const UnitedKingdomFlagIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props} viewBox="0 0 50 30">
      <path d="M0,0 v30 h50 v-30 z" fill="#012169" />
      <path d="M0,0 L60,30 M50,0 L0,30" stroke="#fff" strokeWidth="6" />
      <path d="M0,0 L60,30 M50,0 L0,30" clipPath="url(#t)" stroke="#C8102E" strokeWidth="4" />
      <path d="M25,0 v30 M0,15 h100" stroke="#fff" strokeWidth="10" />
      <path d="M25,0 v30 M0,15 h100" stroke="#C8102E" strokeWidth="6" />
    </SvgIcon>
  );
};

export default UnitedKingdomFlagIcon;
