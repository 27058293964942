import { useCallback, useState } from 'react';

const useDisclosure = (defaultOpen = false) => {
  const [isOpen, setOpen] = useState<boolean>(defaultOpen);
  const onOpen = useCallback(() => setOpen(true), []);
  const onClose = useCallback(() => setOpen(false), []);
  const onToggle = useCallback(() => setOpen((prev) => !prev), []);

  return { isOpen, onOpen, onClose, onToggle };
};

export default useDisclosure;
