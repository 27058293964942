import {
  AppBasic,
  AppConfigV2,
  AppDetailed,
  ConfigChoice,
  PatchBrandAppDTO,
} from '@dap-admin/types';
import { pruneObject } from '@shared/utils';
import { useMemo } from 'react';

export const getApps = (
  existingApps: AppBasic[] = [],
  updatedApps: string[]
): {
  appsToBeRemoved: string[];
  appsToBeAdded: string[];
} => {
  const appsToBeRemoved: string[] =
    existingApps
      ?.filter(({ key }) => !updatedApps.some((app) => app === key))
      .map(({ key }) => key) || [];

  const appsToBeAdded: string[] =
    updatedApps?.filter((app) => !existingApps?.some(({ key }) => key === app)) || [];

  return {
    appsToBeRemoved,
    appsToBeAdded,
  };
};

export function getIfDefaultApp(app: Pick<AppBasic | AppDetailed, 'default' | 'key'>) {
  return app.default;
}

export function getAppObjectFromAppKey(
  basis: Array<Pick<AppBasic | AppDetailed, 'key' | 'name' | 'default' | 'uuid'>>,
  arrayOfAppKeys: Array<string>
) {
  return basis.filter(({ key }) => arrayOfAppKeys.includes(key));
}

export function useServiceFilters<T extends AppBasic | AppDetailed>(
  availableServices: T[]
): { defaultServices: T[]; filteredServices: T[] } {
  const defaultServices = useMemo(
    () => availableServices.filter((service) => getIfDefaultApp(service)),
    [availableServices]
  );

  const filteredServices = useMemo(
    () => availableServices.filter((service) => !getIfDefaultApp(service)),
    [availableServices]
  );

  return { defaultServices, filteredServices };
}

export function getAppDetailed(
  brandServices: Array<AppDetailed>,
  basicServices: Array<AppBasic>
): Array<AppDetailed> {
  return brandServices.filter(({ key }) =>
    basicServices.some((basicService) => basicService.key === key)
  );
}

export function getAvailableServices(
  allServices: Array<AppBasic | AppDetailed>,
  existingServices: Array<AppBasic | AppDetailed>
): Array<AppBasic | AppDetailed> {
  return allServices.filter(
    (avService) => !existingServices.some((exServices) => avService.key === exServices.key)
  );
}

export function getUrlType(config: AppConfigV2, url: string | null) {
  if (config.url) {
    return ConfigChoice.custom;
  } else if (!config.url && !url) {
    return ConfigChoice.custom;
  }
  return ConfigChoice.default;
}

export const mapServiceToPatchDTO = (service: AppDetailed): PatchBrandAppDTO =>
  pruneObject({
    appKey: service.key,
    default: service.default,
    name: service.name,
    url: service.config?.url,
  });
