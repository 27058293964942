export const DataGridClass = {
  cellBold: 'MuiDataGrid-cell--bold',
  cellLink: 'MuiDataGrid-cell--link',
  cellIcon: 'MuiDataGrid-cell--icon',
  cellClickable: 'MuiDataGrid-cell--clickable',
  treeDataTable: 'tree-data-table',
  treeParentRow: 'tree-parent-row',
  treeChildRow: 'tree-child-row',
};

export const TabsClass = {
  underlined: 'underlined-tabs',
};
