import { createTheme, responsiveFontSizes, Theme } from '@mui/material';
import { colors } from './colors';
import { componentsStyles } from './componentsStyles';
import { shadows } from './shadows';
import { typography } from './typography';

let themeV2: Theme = createTheme({
  typography: typography,
  shape: {
    borderRadius: 4,
  },
  spacing: 8,
});

// Shadows
themeV2.shadows[1] = shadows[0]; // d1
themeV2.shadows[2] = shadows[1]; // d2
themeV2.shadows[3] = shadows[2]; // d3
themeV2.shadows[4] = shadows[3]; // d4
themeV2.shadows[5] = shadows[4]; // d5

// Theme composition: using theme options to define other dependent options, https://mui.com/material-ui/customization/theming/
themeV2 = createTheme(themeV2, {
  palette: {
    ...themeV2.palette,
    primary: {
      ...colors.midnightBlue,
      main: colors.midnightBlue[900],
      contrastText: colors.white,
    },
    secondary: {
      ...colors.saffron,
      main: colors.saffron[900],
      contrastText: colors.white,
    },
    success: {
      ...colors.green,
      main: colors.green[900],
      contrastText: colors.white,
    },
    warning: {
      ...colors.alert,
      main: colors.alert[900],
      contrastText: colors.white,
    },
    info: {
      ...colors.skyBlue,
      main: colors.skyBlue[900],
      contrastText: colors.grey[900],
    },
    grey: { ...colors.grey, main: colors.grey.A400, contrastText: colors.black },
    text: {
      primary: colors.grey[900],
      secondary: colors.grey.A400,
      disabled: colors.grey.A200,
    },
    common: {
      black: colors.black,
      white: colors.white,
    },
    gradient: {
      primary: `linear-gradient(282.61deg, ${colors.midnightBlue[900]} 0.09%, ${colors.midnightBlue[800]} 32.38%, ${colors.midnightBlue[700]} 62.89%, ${colors.midnightBlue[600]} 98.77%)`,
      secondary: `linear-gradient(316.01deg, ${colors.saffron[900]} 13.48%, ${colors.saffron[800]} 87.16%)`,
      warning: `linear-gradient(316.01deg, ${colors.alert.A400} 13.48%, ${colors.alert.A200} 87.16%)`,
    },
    background: {
      default: colors.midnightBlue.A200,
      paper: colors.white,
    },
    divider: colors.grey.A100,
    action: {
      ...themeV2.palette.action,
      active: colors.grey.A400,
    },
  },
  components: componentsStyles,
});

export default responsiveFontSizes(themeV2);
