import { TypographyOptions } from '@mui/material/styles/createTypography';

const font = ["'Figtree'", 'sans-serif'].join(',');

export const typography: TypographyOptions = {
  fontFamily: font,
  h1: {
    fontWeight: '700',
    fontSize: 48,
    letterSpacing: '-0.96px',
  },
  h2: {
    fontWeight: 700,
    fontSize: 40,
    letterSpacing: '-1px',
  },
  h3: {
    fontWeight: 700,
    fontSize: 32,
    letterSpacing: '-1px',
  },
  h3Extended: {
    fontWeight: 600,
    fontSize: 28,
    letterSpacing: '-1px',
  },
  h4: {
    fontWeight: 700,
    fontSize: 24,
    letterSpacing: '-0.48px',
  },
  h5: {
    fontWeight: 700,
    fontSize: 20,
  },
  h6: {
    fontWeight: 600,
    fontSize: 18,
    letterSpacing: '0.15px',
  },
  body1: {
    fontSize: 16,
  },
  body2: {
    fontSize: 14,
    letterSpacing: '-0.24px',
  },
  body3: {
    fontSize: 12,
    fontWeight: 500,
    letterSpacing: '-0.12px',
  },
};
