import { ButtonProps } from '@mui/material';
import { ComponentProps, ElementType, forwardRef, PropsWithChildren } from 'react';
import LoadingButton, { LoadingButtonProps } from './LoadingButton';

export type ContainedButtonProps<C extends ElementType> = Omit<
  LoadingButtonProps<C>,
  'variant' | 'loading'
> & {
  loading?: boolean;
  component?: C;
} & ComponentProps<C>;

function ContainedButtonBase<C extends ElementType = 'button'>(
  { children, loading = false, ...rest }: PropsWithChildren<ContainedButtonProps<C>>,
  ref: ButtonProps['ref']
) {
  return (
    <LoadingButton variant="contained" loading={loading} ref={ref} {...rest}>
      {children}
    </LoadingButton>
  );
}

const ContainedButton = forwardRef(ContainedButtonBase);

export default ContainedButton;
