import { useTheme } from '@mui/material';

const CheckboxCheckedIcon = () => {
  const { palette } = useTheme();

  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="20" height="20" rx="4" fill="url(#paint0_linear_10760_176168)" />
      <path d="M8.5 11L6.5 9L5 10.5L8.5 14L15 7.5L13.5 6L8.5 11Z" fill="white" />
      <defs>
        <linearGradient
          id="paint0_linear_10760_176168"
          x1="20.155"
          y1="19.2063"
          x2="-2.84595"
          y2="14.0588"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor={palette.primary['900']} />
          <stop offset="0.327149" stopColor={palette.primary['800']} />
          <stop offset="0.636347" stopColor={palette.primary['700']} />
          <stop offset="1" stopColor={palette.primary['600']} />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default CheckboxCheckedIcon;
