import React from 'react';
import { Box, BoxProps } from '@mui/material';

export interface FlexRowProps extends BoxProps {
  childMargin?: string;
}

const FlexRow: React.FC<FlexRowProps> = ({
  children,
  alignItems = 'center',
  childMargin,
  flexWrap = 'wrap',
  ...props
}) => (
  <Box
    {...props}
    display="flex"
    flexDirection="row"
    flexWrap={flexWrap}
    alignItems={alignItems}
    sx={{ '& > *': { m: childMargin } }}
  >
    {children}
  </Box>
);

export default FlexRow;
