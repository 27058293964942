import { IconButton, Stack, Tooltip, TooltipProps, Typography } from '@mui/material';
import { InformationIcon } from '@shared/custom-icons';

interface Props {
  tooltipText: string;
  placement?: TooltipProps['placement'];
  disabled?: boolean;
}

export default function InfoTooltip({
  tooltipText,
  placement = 'right-start',
  disabled = false,
}: Props) {
  return (
    <Tooltip
      title={
        <Stack direction="row" justifyContent="center" gap={1}>
          <InformationIcon fontSize="small" />
          <Typography variant="body2">{tooltipText}</Typography>
        </Stack>
      }
      placement={placement}
    >
      <span>
        <IconButton size="small" color="inherit" disabled={disabled}>
          <InformationIcon fontSize="inherit" />
        </IconButton>
      </span>
    </Tooltip>
  );
}
