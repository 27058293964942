import { SvgIcon, SvgIconProps } from '@mui/material';

const SearchIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props} width="20" height="20" viewBox="0 0 20 20">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.0013 8.16675C14.0013 11.3884 11.3896 14.0001 8.16797 14.0001C4.94631 14.0001 2.33464 11.3884 2.33464 8.16675C2.33464 4.94509 4.94631 2.33341 8.16797 2.33341C11.3896 2.33341 14.0013 4.94509 14.0013 8.16675ZM14.0279 12.8482C15.0542 11.5651 15.668 9.93759 15.668 8.16675C15.668 4.02461 12.3101 0.666748 8.16797 0.666748C4.02583 0.666748 0.667969 4.02461 0.667969 8.16675C0.667969 12.3089 4.02583 15.6667 8.16797 15.6667C9.93882 15.6667 11.5663 15.053 12.8494 14.0267L15.0787 16.256C15.4042 16.5814 15.9318 16.5814 16.2572 16.256C16.5827 15.9306 16.5827 15.4029 16.2572 15.0775L14.0279 12.8482Z"
        fill={props.color}
      />
    </SvgIcon>
  );
};

export default SearchIcon;
