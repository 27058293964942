import { useMemo } from 'react';
import { Typography, Card, Box, useTheme } from '@mui/material';
import { Link } from 'react-router-dom';
import { BlockTextPalette, CallToAction, CustomLinkField, CustomLinkType } from '@dap-sanity/types';
import type { PortableTextBlock } from '@portabletext/types';
import { PortableText, PortableTextReactComponents } from '@portabletext/react';
import InlineExternalLink from './../../pageContent/InlineExternalLink';
import InlineInternalLink from './../../pageContent/InlineInternalLink';
import { getUrlAndTitleFromCustomLink, getPaletteColors } from '@dap-sanity/utils';
import { ContainedButton } from '@shared/components';

export interface Props {
  title: string;
  textBlock: PortableTextBlock[];
  palette: BlockTextPalette;
  callToAction: CallToAction;
}

const portableComponents: Partial<PortableTextReactComponents> = {
  marks: {
    customLink: ({ children, value }) => {
      const link = value?.link as CustomLinkField;
      if (!link) {
        return children;
      }

      const [href] = getUrlAndTitleFromCustomLink(link);

      if (!href) {
        return children;
      }

      if (link._type == CustomLinkType.ExternalLink) {
        return (
          <InlineExternalLink href={href} blank={link.blank}>
            {children}
          </InlineExternalLink>
        );
      }

      return <InlineInternalLink to={href}>{children}</InlineInternalLink>;
    },
  },
};

export function TextBlock({ title, textBlock, palette, callToAction }: Props) {
  const { palette: themePalette, breakpoints } = useTheme();

  const [callToActionLink] = getUrlAndTitleFromCustomLink(callToAction?.link);

  const callToActionTarget =
    callToAction?.link?._type === CustomLinkType.ExternalLink && callToAction.link.blank
      ? '_blank'
      : '';

  const { backgroundColor, textColor, buttonColor, buttonSx } = useMemo(
    () => getPaletteColors(themePalette, palette),
    [themePalette, palette]
  );

  return (
    <Card
      sx={{
        paddingBlock: 8,
        paddingInline: 4,
        textAlign: 'center',
        color: textColor,
        background: backgroundColor,
        '& p a': {
          color: 'inherit',
        },
        '& p a:hover': {
          color: 'inherit',
          textDecoration: 'none',
        },
        // Use the main-content container to increase padding on larger content areas
        [`@container main-content (min-width: ${breakpoints.values.sm}px)`]: {
          paddingInline: 8,
        },
      }}
    >
      <Typography variant="h3" sx={{ textWrap: 'balance', hyphens: 'auto' }} component="h2">
        {title}
      </Typography>
      <Typography
        variant="h6"
        component="div"
        fontWeight={500}
        lineHeight={24 / 18}
        maxWidth="md"
        marginInline={'auto'}
      >
        <PortableText value={textBlock} components={portableComponents} />
      </Typography>
      {callToAction && callToActionLink && (
        <Box marginTop={7}>
          <ContainedButton
            size="small"
            color={buttonColor}
            to={callToActionLink}
            component={Link}
            sx={buttonSx}
            target={callToActionTarget}
          >
            {callToAction.linkText}
          </ContainedButton>
        </Box>
      )}
    </Card>
  );
}
