import {
  Card,
  CardContent,
  CardHeader,
  CardHeaderOwnProps,
  SxProps,
  Typography,
} from '@mui/material';
import { PropsWithChildren } from 'react';

interface Props extends Pick<CardHeaderOwnProps, 'action'> {
  header?: string;
}

export default function ConfigCard({ header, action, children }: PropsWithChildren<Props>) {
  return (
    <Card variant="outlined" color="primary" sx={cardSx} data-testid={`EditCard-${header}`}>
      <CardHeader
        title={
          header && (
            <Typography variant="h6" noWrap>
              {header}
            </Typography>
          )
        }
        sx={cardHeaderSx}
        action={action}
      />
      <CardContent sx={cardContentSx}>{children}</CardContent>
    </Card>
  );
}

const cardSx: SxProps = {
  height: '100%',
};

const cardHeaderSx: SxProps = {
  '& .MuiCardHeader-content': {
    width: '100%',
    overflow: 'hidden',
  },
};

const cardContentSx: SxProps = {
  padding: 2,
  paddingTop: 1,
};
