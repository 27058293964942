const CheckboxUncheckedIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
      <g id="Property 1=Uncheck">
        <rect
          id="icon bg"
          x="1"
          y="1"
          width="18"
          height="18"
          rx="3"
          stroke="#A9AAB6"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </svg>
  );
};

export default CheckboxUncheckedIcon;
