import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { Step, StepLabel, Stepper as MUIStepper, StepperProps, StepConnector } from '@mui/material';
import React, { Fragment } from 'react';

interface Props {
  activeStep: StepperProps['activeStep'];
  steps: Array<string>;
  skippedSteps?: Set<number>;
}

export default function Stepper({ steps, activeStep, skippedSteps }: Props) {
  const isStepSkipped = (step: number) => {
    return skippedSteps?.has(step);
  };

  const isCompleted = (step: number) => !isStepSkipped(step) && activeStep && activeStep > step;

  return (
    <MUIStepper activeStep={activeStep} alternativeLabel connector={null}>
      {steps.map((label, index) => {
        const stepProps: { completed?: boolean } = {};
        const labelProps: { optional?: React.ReactNode } = {};

        if (isStepSkipped(index)) {
          stepProps.completed = false;
        }

        return (
          <Fragment key={label}>
            {index !== 0 && index < steps.length && (
              <StepConnector
                className={`${activeStep === index ? 'Mui-active' : ''} ${
                  isCompleted(index) ? 'Mui-completed' : ''
                }`}
              />
            )}
            <Step key={label} {...stepProps}>
              <StepLabel
                {...labelProps}
                StepIconComponent={() => {
                  return isCompleted(index) ? <CheckCircleIcon /> : <RadioButtonUncheckedIcon />;
                }}
                className={`${activeStep === index ? 'Mui-active' : ''} ${
                  isCompleted(index) ? 'Mui-completed' : ''
                }`}
              >
                {label}
              </StepLabel>
            </Step>
          </Fragment>
        );
      })}
    </MUIStepper>
  );
}
